<!--<div class="header">-->
<!--  <div class="header_content">-->
<!--    <div class="header_menu_left">-->
<!--      <div class="header_menu_box">-->
<!--        <div class="header_menu_btn">Для бизнеса</div>-->
<!--        <div class="header_menu_btn">Магазины</div>-->
<!--        <div class="header_menu_btn">Помощь</div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="header_menu_right">-->
<!--      <div class="header_menu_right_icon application_icon1"></div>-->
<!--      <div class="header_menu_right_icon application_icon2"></div>-->
<!--      <div class="header_menu_right_icon application_icon3"></div>-->
<!--      <div class="header_menu_right_icon application_icon4"></div>-->
<!--      <div class="header_menu_right_text">Мои объявления</div>-->
<!--      <div class="header_menu_right_photo"></div>-->
<!--      <div class="header_menu_right_name">Александр</div>-->
<!--      <div class="header_menu_right_arrow"></div>-->
<!--      <div class="header_menu_right_btn">Разместить объявление</div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--<div class="bottom_header">-->
<!--  <div class="bottom_header_content">-->
<!--    <div class="bottom_header_content_mobil"></div>-->
<!--    <div class="logo_box_bottom_header"></div>-->
<!--    <div class="menu_box_bottom_header">-->
<!--      <div class="item_mbbh">Авто</div>-->
<!--      <div class="item_mbbh">Недвижимость</div>-->
<!--      <div class="item_mbbh">Работа</div>-->
<!--      <div class="item_mbbh">Услуги</div>-->
<!--      <div class="item_mbbh">Ещё…</div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<form>
  <div class="application">
    <div class="application_content">
      <div class="left_block_application">
        <div class="left_block_application_title">Транспорт</div>
        <div class="lba_box_content">
          <div class="title_lba_box_content hidden_mobil">
            <div class="item_title_lba_box_content">Тариф</div>
            <div class="mini_item_title_lba_box_content">
              <div class="mitlbc_icon"></div>
              <div class="mitlbc_text">
                Подробнее со всеми тарифами можно ознакомиться на <a href="https://www.mover24.ru/tariff" target="_blank">сайте партнера</a>
              </div>
            </div>
          </div>
          <div class="title_lba_box_content mobil_big_title hidden_web">Транспорт</div>
          <div class="values_lba_box_content no_wrap_mobil" #tiersContainer>
            <label
              for="item_tbbiv_{{i}}"
              class="box_big_item_vlbc tier_{{ descriptor.identifier }}"
              [class.bbiv_active]="tierIdentifier == descriptor.identifier"
              *ngFor="let descriptor of tierDescriptors; index as i;"
            >
              <div class="tbbiv_left">
                <div class="item_tbbiv_title">{{ descriptor.name }}</div>
                <div class="item_tbbiv_icon" [title]="'Типы кузовов: ' + descriptor.bodies.join(', ')"></div>
              </div>
              <div class="tbbiv_right">
                <div class="item_tbbiv">
                  <div class="item_tbbiv_left">
                    <div class="item_tbbiv_left_icon tbbiv_icon2"></div>
                    <div class="item_tbbiv_left_text">{{ descriptor.transportTonnage }}</div>
                  </div>
                  <div class="item_tbbiv_right">
                    <div class="item_tbbiv_left_icon tbbiv_icon2"></div>
                    <div class="item_tbbiv_left_text">{{ descriptor.transportVolume }}</div>
                  </div>
                </div>
                <div class="item_tbbiv_checkbox"></div>
                <input id="item_tbbiv_{{i}}" [value]="descriptor.identifier" class="item_tbbiv_checkbox_hidden" name="tierIdentifier" type="radio"
                       [(ngModel)]="tierIdentifier"
                       (ngModelChange)="onChangeTier()">
              </div>
              <div class="item_bbbiv_photo" [style]="'background-image: url(/assets/img/tariffs/' + descriptor.image + ') !important;'"></div>
              <div class="item_bbbiv_info">
                <div class="item_bbbiv_info_name">
                  {{ descriptor.description }}
                  <!--                  <span *ngFor="let body of descriptor.bodies; last as isLast">{{ body }}<span *ngIf="!isLast">, </span></span>-->
                  <div class="sizes_short">
                    {{ descriptor.transportLength }} • {{ descriptor.transportWidth }} • {{ descriptor.transportHeight }}
                  </div>
                </div>
                <div class="item_bbbiv_info_box">
                  <div class="item_bbbiv_info_size">
                    <div class="item_bbbiv_info_size_icon bbbiv_icon1"></div>
                    <div class="item_bbbiv_info_size_text">Длина: <span style="color:#323c5d;">{{ descriptor.transportLength }}</span></div>
                  </div>
                  <div class="item_bbbiv_info_size">
                    <div class="item_bbbiv_info_size_icon bbbiv_icon2"></div>
                    <div class="item_bbbiv_info_size_text">Ширина: <span style="color:#323c5d;">{{ descriptor.transportWidth }}</span></div>
                  </div>
                  <div class="item_bbbiv_info_size">
                    <div class="item_bbbiv_info_size_icon bbbiv_icon3"></div>
                    <div class="item_bbbiv_info_size_text">Высота: <span style="color:#323c5d;">{{ descriptor.transportHeight }}</span></div>
                  </div>
                  <div class="item_bbbiv_info_size">
                    <div class="item_bbbiv_info_size_icon bbbiv_icon4"></div>
                    <div class="item_bbbiv_info_size_text">Объём: <span style="color:#323c5d;">{{ descriptor.transportVolume }}</span></div>
                  </div>
                </div>
                <div  [@visibility]="(descriptor.cost > 0 && !isCalculating) ? 'visible' : 'invisible'" class="tier_cost_container">
                  <div class="item_bbbiv_info_box_mobil_prices" *ngIf="descriptor.cost > 0">
                    {{ descriptor.cost|currency:'RUB':'':'1.0-2' }} ₽
                    <span *ngIf="!isFixedPrice" class="tier_duration">
                      за {{ descriptor.orderDuration|duration:true }}
                    </span>
                  </div>
                  <div class="cost_info">
  <!--                  <div class="item_bbbiv_info_box_mobil_prices additional-price" *ngIf="descriptor.costForHour > 0 && descriptor.orderDuration <= 60">-->
  <!--                    {{ descriptor.costForHour|currency:'RUB':'':'1.0-2' }} ₽-->
  <!--                    <span *ngIf="!isFixedPrice">-->
  <!--                    за 1 час-->
  <!--                    </span>-->
  <!--                  </div>-->
  <!--                  <div class="item_bbbiv_info_box_mobil_prices additional-price" *ngIf="descriptor.costForTwoHours > 0">-->
  <!--                    {{ descriptor.costForTwoHours|currency:'RUB':'':'1.0-2' }} ₽-->
  <!--                    <span *ngIf="!isFixedPrice">-->
  <!--                      за-->
  <!--                      <span *ngIf="descriptor.orderDuration <= 120">2 часа</span>-->
  <!--                      <span *ngIf="descriptor.orderDuration > 120">{{ descriptor.orderDuration|duration:false }}</span>-->
  <!--                    </span>-->
  <!--                  </div>-->
                    <div class="item_bbbiv_info_box_mobil_prices additional-price" *ngIf="!isFixedPrice && descriptor.cost > 0">
                      далее {{ furtherTariffication.periodCost|currency:'RUB':'':'1.0-2' }} ₽ за {{ getMinutesWithUnits(furtherTariffication.tarifficationPeriod) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="box_big_item_vlbc_btn_mobil">{{ tierIdentifier == descriptor.identifier ? 'Выбран' : 'Выбрать' }}</div>
            </label>
          </div>
        </div>
        <div class="lba_box_content loaders_content">
          <div class="title_lba_box_content hidden_web display_mobil">
            <div class="item_title_lba_box_content">Грузчики</div>
            <div class="item_bbcv_box_mini_text">Только по Москве и МО</div>
          </div>
          <div class="title_lba_box_content hidden_mobil">
            <div class="item_title_lba_box_content">Грузчики</div>
            <div class="mini_item_title_lba_box_content">
              <div class="mitlbc_icon"></div>
              <div class="mitlbc_text">Только по Москве и МО</div>
            </div>
          </div>
          <div class="values_lba_box_content">
            <div class="box_big_checkbox_vlbc">
              <input id="item_bbcv_0" [value]="0" class="item_bbcv_checkbox" name="loaders" type="radio"
                     [(ngModel)]="draft.loaders" (ngModelChange)="onChangeLoaders()">
              <label for="item_bbcv_0">
                <div class="item_bbcv_box">
                  <div class="item_bbcv_box_text">Не нужны</div>
                  <div class="item_bbcv_box_mini_text">Справлюсь сам</div>
                </div>
              </label>
            </div>
            <div class="box_big_checkbox_vlbc">
              <input id="item_bbcv_1" [value]="1" class="item_bbcv_checkbox" name="gruzchiki" type="radio"
                     [(ngModel)]="draft.loaders" (ngModelChange)="onChangeLoaders()">
              <label for="item_bbcv_1">
                <div class="item_bbcv_box">
                  <div class="item_bbcv_box_text">1 грузчик</div>
                  <div class="item_bbcv_box_mini_text">600&nbsp;₽ в час. Работает с предметами не тяжелее 40&nbsp;кг</div>
                </div>
              </label>
            </div>
            <div class="box_big_checkbox_vlbc">
              <input id="item_bbcv_2" [value]="2" class="item_bbcv_checkbox" name="gruzchiki" type="radio"
                     [(ngModel)]="draft.loaders" (ngModelChange)="onChangeLoaders()">
              <label for="item_bbcv_2">
                <div class="item_bbcv_box">
                  <div class="item_bbcv_box_text">2 грузчика</div>
                  <div class="item_bbcv_box_mini_text">
                    1200&nbsp;₽ в час. Нужно оплатить минимум 2&nbsp;часа. Работают с предметами не тяжелее 80&nbsp;кг
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="lba_mini_title mobil_big_title">Груз</div>
        <div class="lba_box_content mobil_column">
          <div class="title_lba_box_content">Общий вес</div>
          <div class="values_lba_box_content btns_block_width cargo_block">
            <div class="box_mini_input_vlbc">
              <input type="text" class="input_vlbc" placeholder="кг" name="cargoWeight"
                     [(ngModel)]="draft.cargo_info!.weight" (ngModelChange)="onCommonDraftChange()">
              <div class="lba_info_adress">
                <div class="lba_info_adress_text">Если не знаете, укажите примерный</div>
                <div class="lba_info_adress_question"></div>
              </div>
            </div>
            <div class="box_mini_input_vlbc with_checkbox">
              <div class="checkbox_input_vlbc">
                <input class="input_vlbc_checkbox" type="checkbox" id="assembly"
                       name="assembly"
                       [(ngModel)]="draft.assembly" (ngModelChange)="onChangeAssembly()">
                <label for="assembly" class="input_vlbc_checkbox_text">Нужна сборка/разборка</label>
              </div>
            </div>
            <div class="title_lba_box_content hidden_web">Тип груза</div>
            <div class="box_btns_vlbc">
              <div class="content_box_btns_vlbc">
                <div class="btn_vlbc" *ngFor="let type of cargoTypes"
                     [class.btn_vlbc_active]="isCargoTypeSelected(type)"
                     (click)="onToggleCargoType(type)">{{ type }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="lba_box_content hidden_mobil">
          <div class="title_lba_box_content">Опишите груз</div>
          <div class="values_lba_box_info">
            <textarea class="values_lba_box_textarea" placeholder="Например, груз хрупкий или необычной формы" name="cargoComment"
                      [(ngModel)]="draft.cargo_info!.comment" (ngModelChange)="onCommonDraftChange()"></textarea>
            <!--            <div class="values_lba_box_btn">Добавить фото</div>-->
          </div>
        </div>
        <div class="lba_box_content">
          <div class="title_lba_box_content mobil_big_title">Когда нужен транспорт</div>
          <div class="values_lba_box_content">
            <div class="date_time_input">
              <div class="input_vlbc_data">
                <input type="date" name="orderDateString" [(ngModel)]="orderDate" (ngModelChange)="onChangeOrderDate()" [min]="orderDateMin">
              </div>
              <div class="input_vlbc_data">
                <input type="time" name="orderTimeString" [step]="orderTimeStep" [(ngModel)]="orderTime" (ngModelChange)="onChangeOrderTime()">
              </div>
            </div>
          </div>
        </div>
        <div class="lba_mini_title hidden_mobil">Маршрут</div>
        <div *ngFor="let destination of draft.destinations; index as i; first as isFirst; last as isLast;">
          <div class="lba_box_content">
            <div class="title_lba_box_content mobil_big_title" [class.removable]="i > 1"
                 (click)="onDeleteDestination(i)">
              <span>
                Адрес
                <span *ngIf="isFirst">загрузки</span><span *ngIf="!isFirst">доставки</span>
              </span>
              <div class="confirm_container">
                <div class="confirm" *ngIf="i > 1"><div [@confirm]="deleteDestinationConfirmations[i] ? 'visible' : 'invisible'">удалить?</div></div>
                <img src="/assets/img/delete.svg" alt="Удалить" class="remove" *ngIf="i > 1">
              </div>
            </div>
            <div class="values_lba_box_content">
              <div class="box_big_input_vlbc draft_address">
                <address-field
                  placeholder="Адрес"
                  [(address)]="destination.destination"
                  [name]="'address_' + i"
                  (addressChange)="onCommonDraftChangeWithCalculation()"
                  class="vlbc_address"
                ></address-field>
                <div class="lba_info_adress hidden_mobil">
                  <div class="lba_info_adress_text">
                    <span *ngIf="isFirst">Подача экипажа на загрузку бесплатная</span>
                    <span *ngIf="!isFirst">Водитель позвонит перед выездом на точку</span>
                  </div>
                  <div class="lba_info_adress_question"></div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="displayAdditionalAddressFields[i]">
            <div class="lba_box_content hidden_web">
              <div class="title_lba_box_content mobil_big_title hidden_mobil"></div>
              <div class="values_lba_box_content">
                <div class="box_mini_input_vlbc inputs_header">Этаж</div>
              </div>
            </div>
            <div class="lba_box_content">
              <div class="title_lba_box_content mobil_big_title hidden_mobil"></div>
              <div class="values_lba_box_content">
                <div class="box_mini_input_vlbc">
                  <input class="input_vlbc" placeholder="Этаж" type="number" name="floor[{{i}}]"
                         [(ngModel)]="destination.floor"
                         (ngModelChange)="onChangeFloor(i)">
                  <div class="lba_info_adress hidden_mobil">
                    <div class="lba_info_adress_text">Укажите, если нужны грузчики</div>
                    <div class="lba_info_adress_question"></div>
                  </div>
                </div>
                <div class="box_mini_input_vlbc with_select">
                  <select name="liftType[{{i}}]" class="input_vlbc" [(ngModel)]="destinationLiftTypes[i]"
                          (ngModelChange)="onChangeLiftType(i)">
                    <option *ngFor="let type of liftTypes | mapToIterable" [value]="type.key">{{ type.val }}</option>
                  </select>
                  <!--                <div class="lba_info_adress">-->
                  <!--                  <div class="lba_info_adress_text">Макс. вес 1 единицы груза - 100 кг.</div>-->
                  <!--                  <div class="lba_info_adress_question"></div>-->
                  <!--                </div>-->
                </div>
              </div>
            </div>
            <div class="lba_box_content">
              <div class="title_lba_box_content mobil_big_title hidden_mobil"></div>
              <div class="values_lba_box_content">
                <div class="box_mini_input_vlbc inputs_header" *ngIf="isFirst">Кто отправляет груз</div>
                <div class="box_mini_input_vlbc inputs_header" *ngIf="!isFirst">Кто принимает груз</div>
              </div>
            </div>
          </div>
          <div class="lba_box_content">
            <div class="title_lba_box_content mobil_big_title hidden_mobil"></div>
            <div class="values_lba_box_content">
              <div class="box_mini_input_vlbc" *ngIf="displayAdditionalAddressFields[i]">
                <input type="text" class="input_vlbc" placeholder="Имя" name="contact_name[{{i}}]"
                       [(ngModel)]="destination.contact_name" (ngModelChange)="onCommonDraftChange()">
              </div>
              <div class="box_mini_input_vlbc" *ngIf="displayAdditionalAddressFields[i]">
                <ejs-maskedtextbox mask='+0 000 000-00-00' [(value)]="destination.contact_phone"
                                   placeholder="Телефон"
                                   (valueChange)="onCommonDraftChange()"
                                   cssClass="input_vlbc_contact_phone"></ejs-maskedtextbox>
              </div>
              <div class="checkbox_big_input_vlbc" *ngIf="destination.contact_phone">
                <input class="input_vlbc_checkbox" type="checkbox" id="contactSendSms[{{i}}]"
                       name="contactSendSms[{{i}}]"
                       [(ngModel)]="destination.contact_send_sms" (ngModelChange)="onCommonDraftChange()">
                <label for="contactSendSms[{{i}}]" class="input_vlbc_checkbox_text">Отправить СМС-уведомление
                  контакту</label>
              </div>
              <div class="address_controls">
                <div class="bmiv_mobil" *ngIf="!displayAdditionalAddressFields[i]" (click)="displayAdditionalAddressFields[i] = true">
                  Добавить контакт и другие услуги на адресе
                </div>
                <div class="bmiv_mobil" *ngIf="isLast" (click)="onAddDestination()">Ещё адрес доставки</div>
              </div>
            </div>
          </div>
        </div>
        <div class="lba_mini_title mobil_big_title margin_block">О вас</div>
        <div class="lba_box_content">
          <div class="title_lba_box_content">Имя</div>
          <div class="values_lba_box_contacts">
            <div class="box_contacts_input_vlbc">
              <input type="text" class="input_vlbc_name" name="name" [(ngModel)]="name" (ngModelChange)="onChangeName()">
            </div>
          </div>
        </div>
        <div class="lba_box_content" *ngIf="!userInfoService.isPresent()">
          <div class="title_lba_box_content">Телефон</div>
          <div class="values_lba_box_content input_vlbc_phone_width">
            <div class="box_contacts_input_vlbc with_code">
              <ejs-maskedtextbox mask='+0 000 000-00-00' [(value)]="phone"
                                 placeholder="+_ ___ ___-__-__"
                                 (valueChange)="onChangePhone()" cssClass="input_vlbc_phone"></ejs-maskedtextbox>
            </div>
            <div class="accept_phone_container" *ngIf="!userInfoService.isPresent() && isPhoneValid()">
              <div class="values_lba_box_btn" (click)="onRequestCode()" *ngIf="!isCodeRequesting && !isLoginProcessing">
                <span *ngIf="!isCodeRequested; else repeatSend">Получить смс с кодом</span>
                <ng-template #repeatSend>Отправить код повторно</ng-template>
              </div>
              <div class="values_lba_box_btn accept_status" *ngIf="isCodeRequesting">Запрос кода...</div>
              <div class="values_lba_box_btn accept_status" *ngIf="isLoginProcessing">Подтверждение...</div>
              <div class="code_container" [class.invisible]="!isCodeRequested">
                <ejs-maskedtextbox #acceptCode mask='0000' [(value)]="code" class="accept_code" promptChar="✱"
                                   (valueChange)="onChangeCode()" (focus)="onFocusCodeField($event)"></ejs-maskedtextbox>
              </div>
            </div>
          </div>
        </div>
        <div class="lba_box_content" *ngIf="userInfoService.isPresent()">
          <div class="title_lba_box_content">Телефон</div>
          <div class="values_lba_box_content input_vlbc_phone_width">
            <div class="box_contacts_input_vlbc"><strong>{{ userInfoService.userInfo!.account!.phone|formatPhone }}</strong></div>
            <div class="values_lba_box_btn" (click)="onLogout()">Указать другой номер</div>
          </div>
        </div>
        <div class="lba_box_content">
          <div class="title_lba_box_content">Способ оплаты</div>
          <div class="values_lba_box_content btns_block_width">
            <div class="box_btns_vlbc">
              <div class="content_box_btns_vlbc">
                <div class="btn_vlbc" [class.btn_vlbc_active]="draft.pay_method == 'cash'" (click)="onChangePayMethod('cash')">Наличные</div>
                <div class="btn_vlbc" [class.btn_vlbc_active]="draft.pay_method == 'card'" (click)="onChangePayMethod('card')">Банковская карта</div>
                <!--                <div class="card-controls with_subcontrol" *ngIf="draft.pay_method == 'card'">-->
                <!--                  <div class="subcontrol" *ngIf="activeCard; else noCard" (click)="onRemoveCard(activeCard)">-->
                <!--                    •••• {{ activeCard.mask }} | {{ activeCard.expire_date }}-->
                <!--                    <img src="/assets/img/delete.svg" alt="удалить карту" height="17">-->
                <!--                  </div>-->
                <!--                  <ng-template #noCard>-->
                <!--                    <div class="subcontrol">-->
                <!--                      <span class="add_card" (click)="onAddCard()">Добавить карту</span>-->
                <!--                    </div>-->
                <!--                  </ng-template>-->
                <!--                  <div class="error" *ngIf="isAddCardFail">Карта не добавлена ({{ addCardFailMessage }})</div>-->
                <!--                </div>-->
              </div>
            </div>
          </div>
        </div>
        <div class="lba_box_content">
          <div class="title_lba_box_content">Промокод</div>
          <div class="values_lba_box_content input_vlbc_phone_width">
            <div class="box_contacts_input_vlbc">
              <input type="text" class="input_vlbc_name" size="5" name="promocode" [(ngModel)]="promocode.code">
            </div>
            <div class="values_lba_box_btn" (click)="onEnterPromocode()">Применить</div>
            <div class="item_rbab_text errors shake-horizontal" *ngIf="isVisiblePromocodeError">
              <div class="error">{{ promocodeError }}</div>
            </div>
            <div class="item_rbab_text" *ngIf="isPromocodeEnterSuccess">
              <div class="success">Промокод успешно применён</div>
            </div>
          </div>
        </div>
        <div class="lba_box_content">
          <div class="title_lba_box_content">Комментарий</div>
          <div class="values_lba_box_info">
            <textarea class="values_lba_box_textarea" placeholder="Что ещё стоит знать о заказе"
                      name="comment" [(ngModel)]="draft.comment" (ngModelChange)="onCommonDraftChange()"
                      ></textarea>
          </div>
        </div>
        <div class="left_block_application_footer">
          <ng-container *ngTemplateOutlet="final"></ng-container>
        </div>
      </div>
      <div class="right_block_application_box calculation" #calculationBlock>
        <a id="calculation"></a>
        <div class="item_rbab_sum">
          <div class="item_rbab_res_text bolder">Ваш заказ</div>
        </div>
        <div class="item_rbab" *ngIf="!calculation">
          <div class="item_rbab_box">
            <div class="item_rbab_text_bold">Вы не указали адреса</div>
          </div>
        </div>
        <div class="item_rbab" *ngIf="calculation">
          <div class="item_rbab_sum">
            <div class="item_rbab_res_text">{{ calculation.calculation.minutes|duration }}</div>
          </div>
        </div>
        <div class="item_rbab" *ngIf="isFixedPrice">
          <div class="item_rbab_box">
            <div class="item_rbab_text_bold">Работа водителя</div>
            <div class="item_rbab_text_regular">Тариф «{{ calculation.min_tariff.tier.name }}»</div>
          </div>
          <div class="item_rbab_sum">{{ priorityAreasCost|currency:'RUB':'':'1.0-2' }} ₽</div>
        </div>
        <div class="item_rbab" *ngIf="calculation && !isFixedPrice">
          <div class="item_rbab_box">
            <div class="item_rbab_text_bold">Работа водителя</div>
            <div class="item_rbab_text_regular">Тариф «{{ calculation.min_tariff.tier.name }}», {{ (calculation.min_tariff.min_hours * 60 + calculation.calculation.additional_time + calculation.calculation.additional_night_time)|duration:false }}</div>
          </div>
          <div class="item_rbab_sum">{{ calculation.min_tariff.min_price + calculation.calculation.additional_time_cost|currency:'RUB':'':'1.0-2' }} ₽</div>
<!--          <div class="item_rbab_box">-->
<!--            <div class="item_rbab_text_bold bolder">Первый час работы водителя</div>-->
<!--            <div class="item_rbab_text_regular">Тариф {{ calculation.min_tariff.tier.name }} ∙ {{ (calculation.min_tariff.min_hours * 60)|duration }}</div>-->
<!--          </div>-->
<!--          <div class="item_rbab_sum">{{ calculation.min_tariff.min_price|currency:'RUB':'':'1.0-2' }} ₽</div>-->
        </div>
<!--        <div class="item_rbab" *ngIf="calculation && calculation.calculation.additional_time_cost > 0">-->
<!--          <div class="item_rbab_box">-->
<!--            <div class="item_rbab_text_bold">Доп. время водителя</div>-->
<!--            <div class="item_rbab_text_regular">-->
<!--              {{ (calculation.calculation.additional_time + calculation.calculation.additional_night_time)|duration }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="item_rbab_sum">{{ calculation.calculation.additional_time_cost|currency:'RUB':'':'1.0-2' }} ₽</div>-->
<!--        </div>-->
        <div class="item_rbab" *ngIf="calculation && calculation.calculation.loaders_cost > 0">
<!--          <div class="item_rbab_box">-->
<!--            <div class="item_rbab_text_bold bolder">Работа <span *ngIf="calculation.calculation.loaders_count == 1">грузчика</span><span *ngIf="calculation.calculation.loaders_count == 2">2-х грузчиков</span></div>-->
<!--            <div class="item_rbab_text_regular">-->
<!--              {{ calculation.calculation.loaders_tariff.min_price|currency:'RUB':'':'1.0-2' }} ₽-->
<!--              ∙ {{ (calculation.calculation.loaders_tariff.min_hours * 60)|duration }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="item_rbab_sum">{{ calculation.calculation.loaders_tariff.min_price * calculation.calculation.loaders_count|currency:'RUB':'':'1.0-2' }} ₽</div>-->
          <div class="item_rbab_box">
            <div class="item_rbab_text_bold">Работа <span *ngIf="calculation.calculation.loaders_count == 1">грузчика</span><span *ngIf="calculation.calculation.loaders_count == 2">2-х грузчиков</span></div>
            <div class="item_rbab_text_regular">
              {{ calculation.calculation.loaders_tariff.min_price|currency:'RUB':'':'1.0-2' }} ₽,
              {{ (calculation.calculation.loaders_tariff.min_hours * 60 + calculation.calculation.loaders_additional_time + calculation.calculation.loaders_on_the_way_time)|duration:false }}
            </div>
          </div>
          <div class="item_rbab_sum">{{ calculation.calculation.loaders_tariff.min_price * calculation.calculation.loaders_count + calculation.calculation.loaders_additional_time_cost + calculation.calculation.loaders_on_the_way_cost|currency:'RUB':'':'1.0-2' }} ₽</div>
        </div>
<!--        <div class="item_rbab" *ngIf="calculation && calculation.calculation.loaders_additional_time > 0">-->
<!--          <div class="item_rbab_box">-->
<!--            <div class="item_rbab_text_bold">-->
<!--              Доп. время работы <span *ngIf="calculation.calculation.loaders_count == 1">грузчика</span><span *ngIf="calculation.calculation.loaders_count > 1">грузчиков</span>-->
<!--            </div>-->
<!--            <div class="item_rbab_text_regular">-->
<!--              {{ calculation.calculation.loaders_additional_time|duration }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="item_rbab_sum">{{ calculation.calculation.loader_additional_time_cost|currency:'RUB':'':'1.0-2' }} ₽</div>-->
<!--        </div>-->
<!--        <div class="item_rbab" *ngIf="calculation && calculation.calculation.loaders_on_the_way_time > 0">-->
<!--          <div class="item_rbab_box">-->
<!--            <div class="item_rbab_text_bold">-->
<!--              <span *ngIf="calculation.calculation.loaders_count == 1">Грузчик</span><span *ngIf="calculation.calculation.loaders_count > 1">Грузчики</span> в пути-->
<!--            </div>-->
<!--            <div class="item_rbab_text_regular">-->
<!--              {{ calculation.calculation.loaders_on_the_way_time|duration }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="item_rbab_sum">{{ calculation.calculation.loaders_on_the_way_cost|currency:'RUB':'':'1.0-2' }} ₽</div>-->
<!--        </div>-->
        <div class="item_rbab" *ngIf="calculation && calculation.calculation.ttk_cost > 0">
          <div class="item_rbab_box">
            <div class="item_rbab_text_bold">Въезд в ТТК</div>
          </div>
          <div class="item_rbab_sum">{{ calculation.min_tariff.ttk_price|currency:'RUB':'':'1.0-2' }} ₽</div>
        </div>
        <div class="item_rbab" *ngIf="calculation && calculation.calculation.after_mkad_cost > 0">
          <div class="item_rbab_box">
            <div class="item_rbab_text_bold">Пробег за МКАД</div>
            <div class="item_rbab_text_regular">
              {{ calculation.min_tariff.after_mkad_km_price|currency:'RUB':'':'1.0-2' }} ₽,
              {{ (calculation.calculation.after_mkad_distance / 1000) | round:'ceil' }} км
            </div>
          </div>
          <div class="item_rbab_sum">{{ calculation.calculation.after_mkad_cost|currency:'RUB':'':'1.0-2' }} ₽</div>
        </div>
        <div class="item_rbab" *ngIf="calculation && calculation.calculation.discount > 0">
          <div class="item_rbab_box">
            <div class="item_rbab_text_bold">Скидка</div>
          </div>
          <div class="item_rbab_sum">{{ -calculation.calculation.discount }}%</div>
        </div>
        <div class="item_rbab" *ngIf="calculation && calculation.calculation.fixed_discount > 0">
          <div class="item_rbab_box">
            <div class="item_rbab_text_bold">Скидка</div>
          </div>
          <div class="item_rbab_sum">{{ (-calculation.calculation.fixed_discount)|currency:'RUB':'':'1.0-2' }} ₽</div>
        </div>
        <div class="item_rbab total" *ngIf="calculation">
          <div class="item_rbab_box">
            <div class="item_rbab_text_bold">
              <div class="item_rbab_res_text">Всего</div>
              <div class="item_rbab_text_regular" *ngIf="!isFixedPrice">
                Далее {{ furtherTariffication.periodCost|currency:'RUB':'':'1.0-2' }} ₽ за {{ getMinutesWithUnits(furtherTariffication.tarifficationPeriod) }}
              </div>
            </div>
          </div>
          <div class="item_rbab_res_sum">{{ calculation.calculation.total_cost|currency:'RUB':'':'1.0-2' }} ₽</div>
        </div>
        <div class="item_rbab_btn" (click)="onSendDraft()" *ngIf="!isDraftSent" #orderButton>
          <span *ngIf="!isDraftSending">Заказать</span>
          <span *ngIf="isDraftSending">Заказ оформляется...</span>
        </div>
        <div class="item_rbab_text errors shake-horizontal" *ngIf="errors.length > 0">
          <div class="error" *ngFor="let error of errors">{{ error }}</div>
        </div>
        <div class="item_rbab_text">
          Пока вы ни за что не платите. Когда заказ будет выполнен, его окончательная стоимость придёт вам в смс.
          <br>
          <br>
          Нужна консультация?
          <br>
          Звоните в поддержку <a class="phone" href="tel:+74954143713">+7 (495) 414 37 13</a> или
          <span class="support-chat-launcher" (click)="onOpenChat()">пишите в чат</span>
        </div>
      </div>
      <div class="left_block_application_footer_mobil">
        <ng-container *ngTemplateOutlet="final"></ng-container>
      </div>

      <ng-template #final>
        Нажимая «Заказать», вы соглашаетесь на обработку персональных данных, а также на поручение обработки другим
        лицам, в том числе ООО «8рх» (ИНН 9725016100), на условиях, изложенных в <a [routerLink]="['/privacy-policy']">политике</a>,
        и принимаете <a [routerLink]="['/terms-of-use']">оферту</a> ООО «8рх».
        <br>
        <br>
        Изображения транспорта приведены для примера.
        На заказ может приехать любой автомобиль, соответствующий выбранному тарифу.
      </ng-template>

      <ng-template #serviceDescription>
        <div>
          <a class="item_rbai_icon_box" href="https://www.mover24.ru" target="_blank"></a>
          <div class="item_rbai_text">
            <div class="item_rbai_mini_text">
              Партнёр Авито по грузоперевозкам. Подберёт проверенного исполнителя: обычно это занимает не больше 10 минут.
              Когда заказ будет выполнен, вы получите смс с окончательной стоимостью.
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #serviceWork>
        <div class="item_rbai toggle_item">
          <div class="item_rbai_icon font_icon">💵</div>
          <div class="item_rbai_text">
            <div class="item_rbai_text">Фиксированные цены</div>
            <div class="item_rbai_mini_text">При оформлении заказа вы узнаете, из чего складывается стоимость услуги</div>
          </div>
        </div>
        <div class="item_rbai toggle_item">
          <div class="item_rbai_icon font_icon">👨🏻</div>
          <div class="item_rbai_text">
            <div class="item_rbai_text">Не надо искать исполнителей</div>
            <div class="item_rbai_mini_text">Подберём того, в чьём опыте и навыках уверены</div>
          </div>
        </div>
        <div class="item_rbai toggle_item">
          <div class="item_rbai_icon font_icon">🚛</div>
          <div class="item_rbai_text">
            <div class="item_rbai_text">Проверенные перевозчики</div>
            <div class="item_rbai_mini_text">Mover обучает водителей, контролирует качество их работы и проверяет транспорт</div>
          </div>
        </div>
        <div class="item_rbai toggle_item">
          <div class="item_rbai_icon font_icon">☔️</div>
          <div class="item_rbai_text">
            <div class="item_rbai_text">Помощь в спорных ситуациях</div>
            <div class="item_rbai_mini_text">Если что-то пойдёт не так, вы можете обратиться к Mover или Авито</div>
          </div>
        </div>
      </ng-template>

      <div class="right_block_application_items">
        <div class="item_rbai_title">Расчет перевозки</div>
        <div class="item_rbai_mobil mobil_toggle" [class.opened]="serviceDescriptionOpened" [class.closed]="!serviceDescriptionOpened">
          <ng-container *ngTemplateOutlet="serviceDescription"></ng-container>
          <div class="item_rbai_mobil_box" (click)="serviceDescriptionOpened = !serviceDescriptionOpened">
            <div class="item_rbai_mobil_box_icon"></div>
            <div class="item_rbai_mobil_box_text">Как устроен сервис</div>
          </div>
          <ng-container *ngTemplateOutlet="serviceWork"></ng-container>
        </div>
        <div class="item_rbai_big">
          <ng-container *ngTemplateOutlet="serviceDescription"></ng-container>
          <ng-container *ngTemplateOutlet="serviceWork"></ng-container>
        </div>
      </div>
      <div style="clear: both"></div>
      <div [class.slide-in-right]="isCartVisible"
           [class.slide-out-right]="!isCartVisible"
           [@calculated]="isCalculating ? 'calculating' : 'calculated'" class="mobil_cart" #cartBlock>
        <div class="item_rbab_res_sum" *ngIf="calculation" (click)="onScrollToCalculation()">
          <span class="link">
            <img src="/assets/img/shopping_cart.svg" alt="корзина">
            <span>{{ calculation.calculation.total_cost|currency:'RUB':'':'1.0-2' }} ₽</span>
          </span>
        </div>
        <div class="item_rbab_res_sum" *ngIf="!calculation">
          <span class="link">
            <img src="/assets/img/shopping_cart.svg" alt="корзина">
            <span>0 ₽</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</form>
<!--<div class="footer_mini">-->
<!--  <div class="footer_mini_content">-->
<!--    <div class="top_mini_footer">-->
<!--      <div class="top_mini_footer_menu">Разместить объявление</div>-->
<!--      <div class="top_mini_footer_menu">Объявления</div>-->
<!--      <div class="top_mini_footer_menu">Магазины</div>-->
<!--      <div class="top_mini_footer_menu">Помощь</div>-->
<!--      <div class="top_mini_footer_menu">Безопасность</div>-->
<!--      <div class="top_mini_footer_menu">Реклама на сайте</div>-->
<!--      <div class="top_mini_footer_menu">О компании</div>-->
<!--      <div class="top_mini_footer_menu">Карьера</div>-->
<!--      <div class="top_mini_footer_menu_bold">Мобильное приложение</div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--<div class="bottom_mini_footer">-->
<!--  <div class="bottom_mini_footer_content">-->
<!--    <div class="bottom_mini_footer_item">Авито — сайт объявлений. © ООО «КЕХ еКоммерц» 2007–2019.</div>-->
<!--    <a href="" class="bottom_mini_footer_href">Условия использования Авито.</a>-->
<!--    <a href="" class="bottom_mini_footer_href">Политика о данных пользователей.</a>-->
<!--    <div class="bottom_mini_footer_item">Оплачивая услуги на Авито, вы принимаете</div>-->
<!--    <a href="" class="bottom_mini_footer_href">оферту.</a>-->
<!--  </div>-->
<!--</div>-->

<!--<div class="mobil_footer">-->
<!--  <div class="mobil_footer_mini_content">-->
<!--    <div class="mobil_top_mini_footer">-->
<!--      <div class="mobil_top_mini_footer_menu">Помощь</div>-->
<!--      <div class="mobil_top_mini_footer_menu">О компании</div>-->
<!--      <div class="mobil_top_mini_footer_menu">Вакансии</div>-->
<!--      <div class="mobil_top_mini_footer_menu">Безопасность</div>-->
<!--      <div class="mobil_top_mini_footer_menu">Реклама на сайте</div>-->
<!--      <div class="mobil_top_mini_footer_menu">Оферта</div>-->
<!--      <div class="mobil_top_mini_footer_menu">Политика о данных пользователей</div>-->
<!--      <div class="mobil_top_mini_footer_menu">Условия использования авито</div>-->
<!--    </div>-->
<!--    <div class="mobil_top_mini_footer_copir">© ООО «КЕХ еКоммерц» 2007-2021</div>-->
<!--    <div class="mobil_bottom_mini_footer">-->
<!--      <div class="item_mobil_bottom_mini_footer">Мобильное приложение</div>-->
<!--      <div class="item_mobil_bottom_mini_footer">Полная версия</div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->


<!--<div class="container">-->
<!--  <div class="row">-->
<!--    <div class="col-8">-->
<!--      <h1>Подтверждение заявки</h1>-->
<!--      <form>-->
<!--        <h2>Дата заказа</h2>-->
<!--        <div class="row mb-3">-->
<!--          <div class="col">-->
<!--            <ejs-maskedtextbox mask="00.00.0000"-->
<!--                               [dpDayPicker]="dateConfig"-->
<!--                               mode="day"-->
<!--                               name="orderDate"-->
<!--                               [(ngModel)]="orderDate"-->
<!--                               (ngModelChange)="onChangeOrderDate()"-->
<!--                               theme="dp-material"-->
<!--            ></ejs-maskedtextbox>-->
<!--          </div>-->
<!--          <div class="col">-->
<!--            <ejs-maskedtextbox mask="00:00"-->
<!--                               [dpDayPicker]="timeConfig"-->
<!--                               mode="time"-->
<!--                               name="orderTime"-->
<!--                               [(ngModel)]="orderTime"-->
<!--                               (ngModelChange)="onChangeOrderTime()"-->
<!--                               theme="dp-material"-->
<!--            ></ejs-maskedtextbox>-->
<!--          </div>-->
<!--        </div>-->
<!--        <h2>Маршрут</h2>-->
<!--        <div class="mb-3" *ngFor="let destination of draft.destinations; let i = index;">-->
<!--          <label class="form-label">Адрес {{ i + 1 }}</label>-->
<!--          <address-field-->
<!--            [placeholder]="i == 0 ? 'Куда приехать исполнителю' : 'Где разгрузить груз'"-->
<!--            [(address)]="destination.destination"-->
<!--            (addressChange)="onCommonDraftChangeWithCalculation()"-->
<!--          ></address-field>-->
<!--          <div class="address-options mt-2">-->
<!--            <div class="row">-->
<!--              <div class="col-6">-->
<!--                <label class="form-label">Этаж</label>-->
<!--                <input type="number" name="floor[{{i}}]" class="form-control" [(ngModel)]="destination.floor"-->
<!--                       (ngModelChange)="onChangeFloor(i)">-->
<!--              </div>-->
<!--              <div class="col-6" *ngIf="destination.floor && destination.floor > 0">-->
<!--                <label class="form-label">Подъём/спуск</label>-->
<!--                <select class="form-select" name="liftType[{{i}}]" [(ngModel)]="destinationLiftTypes[i]"-->
<!--                        (ngModelChange)="onChangeLiftType(i)">-->
<!--                  <option *ngFor="let type of liftTypes | mapToIterable" [value]="type.key">{{ type.val }}</option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row">-->
<!--              <div class="col-6">-->
<!--                <label class="form-label">Имя контакта</label>-->
<!--                <input type="text" name="contact_name[{{i}}]" class="form-control"-->
<!--                       [(ngModel)]="destination.contact_name" (ngModelChange)="onCommonDraftChange()">-->
<!--              </div>-->
<!--              <div class="col-6">-->
<!--                <label class="form-label">Телефон контакта</label>-->
<!--                <ejs-maskedtextbox mask='+0 000 000-00-00' [(value)]="destination.contact_phone"-->
<!--                                   (valueChange)="onCommonDraftChange()"></ejs-maskedtextbox>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row" *ngIf="destination.contact_phone">-->
<!--              <div class="col">-->
<!--                <div class="form-check">-->
<!--                  <input type="checkbox" class="form-check-input" name="contactSendSms[{{i}}]"-->
<!--                         [(ngModel)]="destination.contact_send_sms" id="contactSendSms[{{i}}]"-->
<!--                         (ngModelChange)="onCommonDraftChange()">-->
<!--                  <label class="form-label" for="contactSendSms[{{i}}]">Отправить СМС-уведомление контакту</label>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div>-->
<!--          <button type="button" class="btn btn-link" (click)="onAddDestination()">Добавить ещё адрес</button>-->
<!--        </div>-->

<!--        <h2>Тариф</h2>-->
<!--        <div class="mb-3">-->
<!--          <label class="form-label">Авто</label>-->
<!--          <select class="form-select" name="tier" [(ngModel)]="tierIdentifier" (ngModelChange)="onChangeTier()">-->
<!--            <option *ngFor="let tier of tiers" [value]="tier.identifier">{{ tier.name }}-->
<!--              ({{ tier.tier_capacity_description }})-->
<!--            </option>-->
<!--          </select>-->
<!--        </div>-->
<!--        <div class="mb-3">-->
<!--          <label class="form-label">Грузчики</label>-->
<!--          <select class="form-select" name="loaders" [(ngModel)]="draft.loaders"-->
<!--                  (ngModelChange)="onCommonDraftChangeWithCalculation()">-->
<!--            <option value="0">Без грузчиков</option>-->
<!--            <option value="1">Один грузчик</option>-->
<!--            <option value="2">Два грузчика</option>-->
<!--          </select>-->
<!--        </div>-->

<!--        <h2>Груз</h2>-->
<!--        <div class="row mb-3">-->
<!--          <div class="col-6">-->
<!--            <div class="input-group">-->
<!--              <input class="form-control" type="text" name="cargoWeight" [(ngModel)]="draft.cargo_info!.weight"-->
<!--                     (ngModelChange)="onCommonDraftChange()">-->
<!--              <span class="input-group-text">кг</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-6">-->
<!--            <select class="form-select" name="assembly" [(ngModel)]="assembly" (ngModelChange)="onChangeAssembly()">-->
<!--              <option value="0"></option>-->
<!--              <option value="1">Сборка/разборка</option>-->
<!--            </select>-->
<!--            <div class="form-text">Помощь грузчика</div>-->
<!--          </div>-->
<!--          <div class="row mb-3">-->
<!--            <div class="col cargo-types">-->
<!--              <span class="item" [class.selected]="isCargoTypeSelected(type)" *ngFor="let type of cargoTypes"-->
<!--                    (click)="onToggleCargoType(type)"><span>{{ type }}</span></span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="mb-3">-->
<!--            <label class="form-label">Опишите груз</label>-->
<!--            <textarea class="form-control" rows="8" placeholder="Что ещё специалисту стоит знать о грузе?" name="cargoComment"-->
<!--                      [(ngModel)]="draft.cargo_info!.comment" (ngModelChange)="onCommonDraftChange()"></textarea>-->
<!--          </div>-->
<!--        </div>-->

<!--        <h2>Заказчик</h2>-->
<!--        <div class="mb-3">-->
<!--          <label class="form-label">Имя</label>-->
<!--          <input type="text" class="form-control" name="name" [(ngModel)]="name" (ngModelChange)="onChangeName()">-->
<!--        </div>-->
<!--        <div class="mb-3" *ngIf="!userInfoService.isPresent()">-->
<!--          <ejs-maskedtextbox mask='+0 000 000-00-00' [(value)]="phone"-->
<!--                             (valueChange)="onChangePhone()"></ejs-maskedtextbox>-->
<!--        </div>-->
<!--        <div class="mb-3 row" *ngIf="!userInfoService.isPresent() && isPhoneValid()">-->
<!--          <div class="col">-->
<!--            <button type="button" class="btn btn-sm btn-outline-secondary" (click)="onRequestCode()">Запросить код-->
<!--            </button>-->
<!--            <div class="spinner-grow spinner-grow-sm ms-2 text-info" role="status" *ngIf="isCodeRequesting">-->
<!--              <span class="visually-hidden">Запрос кода...</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col login-col">-->
<!--            <div class="spinner-grow spinner-grow-sm me-2 text-info" role="status" *ngIf="isLoginProcessing">-->
<!--              <span class="visually-hidden">Авторизация...</span>-->
<!--            </div>-->
<!--            <ejs-maskedtextbox mask='0000' placeholder="пароль из смс" [(value)]="code"-->
<!--                               (valueChange)="onChangeCode()"></ejs-maskedtextbox>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="mb-3 row" *ngIf="userInfoService.isPresent()">-->
<!--          <div class="col">-->
<!--            Телефон: <strong>{{ userInfoService.userInfo!.account!.phone|formatPhone }}</strong>-->
<!--            <button class="btn btn-sm btn-outline-secondary ms-2" type="button" (click)="onLogout()">Другой номер-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->

<!--        <h2 *ngIf="userInfoService.isPresent()">Способ оплаты</h2>-->
<!--        <div class="mb-3" *ngIf="userInfoService.isPresent()">-->
<!--          <div class="form-check">-->
<!--            <input class="form-check-input" type="radio" [(ngModel)]="draft.pay_method"-->
<!--                   (ngModelChange)="onCommonDraftChange()" name="payMethod" value="cash"-->
<!--                   id="pay-method-cash">-->
<!--            <label class="form-check-label" for="pay-method-cash">-->
<!--              Наличные-->
<!--            </label>-->
<!--          </div>-->
<!--          <div class="form-check">-->
<!--            <input class="form-check-input" type="radio" [(ngModel)]="draft.pay_method"-->
<!--                   (ngModelChange)="onCommonDraftChange()" name="payMethod" value="card"-->
<!--                   id="pay-method-card">-->
<!--            <label class="form-check-label" for="pay-method-card">-->
<!--              Банковская карта-->
<!--            </label>-->
<!--          </div>-->
<!--          <div class="bank-cards" *ngIf="draft.pay_method == 'card'">-->
<!--            <div class="bank-card" *ngFor="let card of bankCards" [class.expired]="isCardExpired(card)"-->
<!--                 [class.active]="card.active" [title]="card.active ? 'Активная карта' : ''">-->
<!--              <div class="mask">•••• {{ card.mask }}</div>-->
<!--              <div class="expiration">{{ card.expire_date }}</div>-->
<!--              <div class="icon active-flag" *ngIf="card.active"></div>-->
<!--            </div>-->
<!--            <div class="bank-card add-card" title="Добавить карту" (click)="onAddCard()">-->
<!--              <div class="button" *ngIf="!isAddCardActive">[ + ]</div>-->
<!--              <div class="button" *ngIf="isAddCardActive">-->
<!--                <div class="spinner-grow text-primary" role="status">-->
<!--                  <span class="visually-hidden">Loading...</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="add-card-fail" *ngIf="isAddCardFail">-->
<!--              Ошибка добавления карты-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="mb-3">-->
<!--          <label class="form-label">Комментарии</label>-->
<!--          <textarea class="form-control" rows="8" placeholder="Что ещё специалисту нужно знать о заказе" name="comment"-->
<!--                    [(ngModel)]="draft.comment" (ngModelChange)="onCommonDraftChange()"></textarea>-->
<!--        </div>-->
<!--      </form>-->
<!--    </div>-->
<!--    <div class="col-4">-->
<!--      <div class="card calculation" style="width: 18rem;">-->
<!--        <div class="card-body">-->
<!--          <div class="card-title">-->
<!--            <div class="calculation-row head">-->
<!--              <div class="head-col">Ваш заказ</div>-->
<!--              <div class="val-col" *ngIf="calculation">{{ calculation.calculation.total_cost }} Р</div>-->
<!--              <div class="val-col" *ngIf="!calculation">0 Р</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="card-text body" *ngIf="calculation">-->
<!--            <div class="calculation-row">-->
<!--              <div class="head-col">-->
<!--                Минимальный тариф-->
<!--                <div class="sub">Тариф {{ calculation.min_tariff.tier.name }} ∙ {{ calculation.min_tariff.min_hours }}-->
<!--                  ч-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="val-col">{{ calculation.min_tariff.min_price }} Р</div>-->
<!--            </div>-->
<!--            <div class="calculation-row" *ngIf="calculation.calculation.ttk_cost > 0">-->
<!--              <div class="head-col">-->
<!--                Въезд в ТТК-->
<!--              </div>-->
<!--              <div class="val-col">{{ calculation.min_tariff.ttk_price }} Р</div>-->
<!--            </div>-->
<!--            <div class="calculation-row" *ngIf="calculation.calculation.after_mkad_cost > 0">-->
<!--              <div class="head-col">-->
<!--                Пробег за МКАД-->
<!--                <div class="sub">{{ calculation.min_tariff.after_mkad_km_price }} Р-->
<!--                  ∙ {{ (calculation.calculation.after_mkad_distance / 1000) | round:'ceil' }} км-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="val-col">{{ calculation.calculation.after_mkad_cost }} Р</div>-->
<!--            </div>-->
<!--            <div class="calculation-row" *ngIf="calculation.calculation.additional_time_cost > 0">-->
<!--              <div class="head-col">-->
<!--                {{ getAdditionalTimeString(calculation.calculation.additional_time) }}-->
<!--                <div class="sub">{{ calculation.min_tariff.additional_hour_price / 60|number:'1.0-2' }} Р-->
<!--                  ∙ {{ calculation.calculation.additional_time }} мин.-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="val-col">{{ calculation.calculation.additional_time_cost }} Р</div>-->
<!--            </div>-->
<!--            <div class="calculation-row" *ngIf="calculation.calculation.loaders_cost > 0">-->
<!--              <div class="head-col">-->
<!--                {{ getLoadersWorkHoursString(calculation.calculation.loaders_work_time, calculation.calculation.loaders_count) }}-->
<!--              </div>-->
<!--              <div class="val-col">{{ calculation.calculation.loaders_sum_cost }} Р</div>-->
<!--            </div>-->
<!--            <div class="controls">-->
<!--              <button type="button" class="btn btn-primary" (click)="onSendDraft()" [disabled]="isDraftSending"-->
<!--                      *ngIf="!isDraftSent">-->
<!--                <span *ngIf="!isDraftSending">Отправить заявку</span>-->
<!--                <span *ngIf="isDraftSending">Заявка отправляется...</span>-->
<!--              </button>-->
<!--              <div class="draft-sent" *ngIf="isDraftSent">-->
<!--                Заявка №{{ draft.id }} отправлена на подбор. Отследить её статус можно в личном кабинете на-->
<!--                <a [href]="personalAccountUrl">www.mover24.ru</a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="card-text body" *ngIf="!calculation">-->
<!--            Для расчёта заказа укажите все адреса-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
