import {AfterViewInit, Component, ElementRef, HostListener, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {OrderDraft} from "../_models/order-draft";
import {DestinationPoint} from "../_models/destination-point";
import {Destination} from "../_models/destination";
import {ExtraSearchParams} from "../_models/extra-search-params";
import {TariffTier} from "../_models/tariff-tier";
import {TariffService} from "../_services/tariff.service";
import {Account} from "../_models/account";
import {UserNameUtils} from "../_utils/user-name-utils";
import {OrderDraftService} from "../_services/order-draft.service";
import {plural} from "ru-plurals";
import {LoginResult, LoginService, RestorePasswordResult} from "../_services/login.service";
import {AlertService} from "../_services/alert.service";
import {RegisterAccountService, RegisterResult} from "../_services/register-account.service";
import {debounceTime, delay, finalize, map, switchMap, tap} from "rxjs/operators";
import {UserInfoService} from "../_services/user-info.service";
import {LogoutService} from "../_services/logout.service";
import {BankCard} from "../_models/bank-card";
import {BankCardService} from "../_services/bank-card.service";
import {BankCardUtils} from "../_utils/bank-card-utils";
import {DraftStorageService} from "../_services/draft-storage.service";
import {AddBankCardStatus} from "../_enums/add-bank-card-status";
import {environment} from "../../environments/environment";
import {MeService} from "../_services/me.service";
import {DraftSpecial} from "../_models/draft-special";
import * as moment from "moment";
import {Moment} from "moment/moment";
import {LiftType} from "../_enums/lift-type";
import {LIFT_TYPES} from "../_maps/lift-types";
import {of, Subject} from "rxjs";
import {LiftTypeUtils} from "../_utils/lift-type-utils";
import {CargoInfo} from "../_models/cargo-info";
import {CARGO_TYPES} from "../_arrays/cargo-types";
import {TierDescriptor} from "../_models/tier-descriptor";
import {TIER_DESCRIPTORS} from "../_maps/tier-descriptors";
import {MaskedTextBoxComponent, MaskFocusEventArgs} from "@syncfusion/ej2-angular-inputs";
import {animate, keyframes, state, style, transition, trigger} from "@angular/animations";
import {IFrameResizerService} from "../_services/i-frame-resizer.service";
import {JivoSiteService} from "../_services/jivo-site.service";
import {PromoService} from "../_services/promo.service";
import {EnterPromocodeResult} from "../_enums/enter-promocode-result";
import {PromoCode} from "../_models/promo-code";

declare var parentIFrame: any;
declare var ym: any;

// шаг в минутах при выборе времени
const TIME_STEP = 15;
// через сколько минут по умолчанию начнётся заказ
const ORDER_START_TIME_OFFSET = 30;
const ARRIVAL_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const hoursPlural = plural('час', 'часа', 'часов');
const loadersPlural = plural('грузчика', 'грузчиков', 'грузчиков');
const minutesPlural = plural('минута', 'минуты', 'минут');

// максимальная ширина страницы, при которой отображается блок корзины вместо калькуляции
const WIDTH_FOR_CART = 1200;
// максимальная ширина страницы, при которой корзина отображается в уменьшенном виде
const WIDTH_FOR_SMALL_CART = 1200;
// смещение корзины нормальных размеров
const NORMAL_CART_OFFSET = 16;
// смещение уменьшенной корзины
const SMALL_CART_OFFSET = 16;
// Варианты калькуляций с продолжительностью заказа в минутах.
// Первый элемент вложенного массива - это поле в дескрипторе для хранения соответствующей стоимости.
// Второй элемент - продолжительность в минутах.
const CALCULATION_MINUTES_VARIANTS: (string | number)[][] = [/*['costForHour', 60], ['costForTwoHours', 120]*/];

class FurtherTariffication {
  tarifficationPeriod = 0;
  periodCost = 0;
}

@Component({
  selector: 'app-create-draft',
  templateUrl: './create-draft.component.html',
  styleUrls: ['./create-draft.component.scss'],
  animations: [
    trigger('shoppingCart', [
      state('visible', style({opacity: 1})),
      state('invisible', style({opacity: 0})),
      transition('visible <-> invisible', [
        animate('1s')
      ])
    ]),
    trigger('calculated', [
      state('calculated', style({
        backgroundColor: '#00AAFF'
      })),
      transition('* -> calculated', animate('1.5s ease', keyframes([
        style({ backgroundColor: '#00AAFF', transform: 'scale(1)', offset: 0}),
        style({ backgroundColor: '#ffffff', transform: 'scale(1.1)', offset: 0.5 }),
        style({ backgroundColor: '#00AAFF', transform: 'scale(1)', offset: 1}),
      ])))
    ]),
    trigger('confirm', [
      state('visible', style({opacity: 1, right: "0%"})),
      state('invisible', style({opacity: 0, right: "-100%"})),
      transition('visible <-> invisible', [
        animate('0.5s ease-in-out')
      ])
    ]),
    trigger('visibility', [
      state('visible', style({opacity: 1})),
      state('invisible', style({opacity: 0})),
      transition('visible <-> invisible', [
        animate('0.5s ease-in-out')
      ])
    ])
  ]
})
export class CreateDraftComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('acceptCode') acceptCodeField!: MaskedTextBoxComponent;
  @ViewChild('calculationBlock') calculationBlock?: ElementRef;
  @ViewChild('cartBlock') cartBlock?: ElementRef;
  @ViewChild('tiersContainer') tiersContainer!: ElementRef;
  @ViewChild('orderButton') orderButton!: ElementRef;

  draft = new OrderDraft();

  serviceDescriptionOpened = false;
  tiers: TariffTier[] = [];
  tierDescriptors: TierDescriptor[] = [];
  tierDescriptorsMap = new Map<string, TierDescriptor>();
  tierIdentifier?: string;
  name = "";
  phone = "";
  code = "";
  calculation?: any;
  isCodeRequested = false;
  isCodeRequesting = false;
  isLoginProcessing = false;
  bankCards: BankCard[] = [];
  activeCard: BankCard | undefined;
  isAddCardActive = false;
  isAddCardFail = false;
  addCardFailMessage = '';
  isDraftSending = false;
  isDraftSent = false;
  personalAccountUrl: string;
  errors: string[] = [];
  isFixedPrice = false;
  priorityAreasCost = 0;
  isVisiblePromocodeError = false;
  isPromocodeEnterSuccess = false;
  promocodeError = '';
  promocode = new PromoCode();

  orderDate = moment().format('YYYY-MM-DD');
  orderTime = moment().format('HH:mm');
  orderTimeStep = (TIME_STEP * 60).toString();
  orderDateMin = moment().format('YYYY-MM-DD');

  destinationLiftTypes: LiftType[] = [];
  liftTypes = LIFT_TYPES;
  cargoTypes = CARGO_TYPES;

  calculations: any = {};

  /**
   * Период тарификации транспорта в минутах
   */
  transportTarifficationPeriod = 1;
  /**
   * Стоимость одного периода транспорта
   */
  transportTarifficationPeriodPrice = 0;
  /**
   * Период тарификации грузчиков в минутах
   */
  loadersTarifficationPeriod = 1;
  /**
   * Стоимость одного периода тарификации грузчиков
   */
  loadersTarifficationPeriodPrice = 0;
  /**
   * Период тарификации грузчиков в пути в минутах
   */
  loadersOnTheWayTarifficationPeriod = 1;
  /**
   * Стоимость одного периода тарификации грузчиков в пути
   */
  loadersOnTheWayTarifficationPeriodPrice = 0;
  furtherTariffication = new FurtherTariffication();

  private draftSpecial = new DraftSpecial('avito', 'first');
  private tierIdentifierToInit?: string;
  private phonePattern = /^\d{11}$/;
  private fixPhonePattern = /^8(.*)/;

  private addCardRequestId?: string;

  private calculationStream = new Subject<[OrderDraft, DraftSpecial]>();
  isCalculating: boolean = false;

  private fixPositionStream = new Subject<any>();
  private lastPageInfo: any|undefined;

  private calculationVariants: { [key: string]: (string|null)[]|(number|null)[]|(boolean|null)[] } = {}

  deleteDestinationConfirmations: { [key: number]: boolean } = {};

  private cancelConfirmationTimer: any;

  displayAdditionalAddressFields: { [ key: number]: boolean } = {};

  private activeDestination?: Destination;

  isCartVisible = false;
  private switchCartVisibilityStream = new Subject<void>();

  private reloadTiersTimer: any|undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tariffService: TariffService,
    private draftService: OrderDraftService,
    private loginService: LoginService,
    private logoutService: LogoutService,
    private registerAccountService: RegisterAccountService,
    public userInfoService: UserInfoService,
    private meService: MeService,
    private bankCardService: BankCardService,
    private draftStorageService: DraftStorageService,
    private iFrameResizerService: IFrameResizerService,
    private alertService: AlertService,
    private jivoSiteService: JivoSiteService,
    private promoService: PromoService,
    private zone: NgZone
  ) {
    this.personalAccountUrl = environment.personalAccount;
    this.initDraft();
  }

  ngOnInit(): void {
    this.initCalculationStream();
    this.initPhone();
    this.initOrderDate();
    this.restoreDraft();
    this.loadTiers();

    this.route.queryParams.subscribe((params: Params) => {
      this.initDraftByQueryParams(params);
    });

    if(this.userInfoService.isPresent())
      this.initAuthorizedUser();

    this.initAdditionalAddressFields();

    this.initIframeHandlers();
  }

  ngOnDestroy(): void {
    this.destroyIframeHandlers();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0
      })
    }, 300);
  }

  private initIframeHandlers(): void {
    this.iFrameResizerService.addPageInfoListener('draft', (info: any) => this.onReceivedPageInfo(info));
    this.initFixPositionStream();
    this.initSwitchCartVisibilityStream();
  }

  private destroyIframeHandlers(): void {
    this.iFrameResizerService.removePageInfoListener('draft');
  }

  initFixPositionStream(): void {
    this.fixPositionStream
      .pipe(
        debounceTime(250)
      )
      .subscribe(
        info => {
          this.fixCalculationBlockPosition(info);
          this.fixCartBlockPosition(info);
        }
      );
  }

  initSwitchCartVisibilityStream(): void {
    this.switchCartVisibilityStream.
      pipe(
        debounceTime(1000)
      )
      .subscribe(() => this.switchCartVisibility())
    ;
  }

  private initCalculation(): void {
    this.initPriorityAreas();
    this.initTarifficationPeriods();
  }

  private initPriorityAreas(): void {
    this.priorityAreasCost = 0;
    this.isFixedPrice = false;

    if(this.calculation && this.calculation.calculation && this.calculation.calculation.areas) {
      for(const area of this.calculation.calculation.areas) {
        if(area.method === 'priority_point_in') {
          this.priorityAreasCost += area.cost;
          this.isFixedPrice = true;
        }
      }
    }
  }

  private initTarifficationPeriods(): void {
    this.initTransportTarifficationPeriod();
    this.initLoadersTarifficationPeriod();
    this.initLoadersOnTheWayTarifficationPeriod();
    this.initFurtherTariffication();
  }

  private initTransportTarifficationPeriod(): void {
    [ this.transportTarifficationPeriod, this.transportTarifficationPeriodPrice ] = (this.calculation && this.calculation.calculation.transport_tariff)
      ? this.extractTarifficationPeriodFromTariff(this.calculation.calculation.transport_tariff)
      : [ 1, 0 ];
  }


  private initLoadersTarifficationPeriod(): void {
    [ this.loadersTarifficationPeriod, this.loadersTarifficationPeriodPrice ] = (this.calculation && this.calculation.calculation.loaders_tariff)
      ? this.extractTarifficationPeriodFromTariff(this.calculation.calculation.loaders_tariff)
      : [ 1, 0 ];
  }

  private initLoadersOnTheWayTarifficationPeriod(): void {
    [ this.loadersOnTheWayTarifficationPeriod, this.loadersOnTheWayTarifficationPeriodPrice ] = (this.calculation && this.calculation.calculation.loaders_tariff)
      ? this.extractTarifficationPeriodFromTariff(this.calculation.calculation.loaders_tariff, 'wait_period_tariffication', 'wait_period_price')
      : [ 1, 0 ];
  }

  private initFurtherTariffication(): void {
    if(this.isFixedPrice) {
      this.furtherTariffication = new FurtherTariffication();
      return;
    }

    let periods = [ this.transportTarifficationPeriod, this.loadersOnTheWayTarifficationPeriod ]
      .filter(v => v > 1);

    let periodsAvg = periods.length > 0 ? periods.reduce((accumulator, period) => accumulator + period, 0) / periods.length : 1;
    let periodsCost = this.transportTarifficationPeriodPrice + this.loadersTarifficationPeriodPrice;

    if(this.calculation?.calculation?.discount > 0)
      periodsCost -= Math.round(periodsCost * this.calculation.calculation.discount) / 100;

    this.furtherTariffication.tarifficationPeriod = periodsAvg;
    this.furtherTariffication.periodCost = periodsCost;
  }

  private extractTarifficationPeriodFromTariff(tariff: any, periodProp = 'additional_period_tariffication', periodPriceProp = 'additional_period_price'): [number, number] {
    if(tariff.extension && tariff.extension.tariff) {
      const tariffExtension = JSON.parse(tariff.extension.tariff);
      return [ tariffExtension[periodProp], tariffExtension[periodPriceProp] ];
    } else {
      return [1, 0];
    }
  }

  private initCalculationStream(): void {
    this.calculationStream = new Subject<[OrderDraft, DraftSpecial]>();
    this.calculationStream
      .pipe(
        delay(500),
        switchMap(d => {
          const [draft, special] = d;

          if(!this.isDraftCalculable(draft)) {
            this.calculateDefaultDraft();

            return of([OrderDraft.clone(draft), undefined]);
          }

          this.isCalculating = true;

          return this.draftService
            .requestMultiCalculation(draft, this.calculationVariants, special)
            .pipe(
              finalize(() => this.isCalculating = false),
              map(c => [ draft, c ])
            );
        })
      )
      .subscribe(
        dc => {
          let draft = dc[0] as OrderDraft;
          let calculations = dc[1] as any[]|undefined;

          if(!calculations) {
            this.calculation = undefined;
            this.initCalculation();
            this.applyCartVisibility();
            return;
          }

          for(let i = 0; i < calculations.length; i++) {
            let calculation = calculations[i];
            let calculationTier = calculation['variant']['tier'] as string;
            let calculationMinutes = calculation['variant']['customMinutes'] as number|null;

            calculation.is_default = draft.is_default;

            if(calculationMinutes == null) {
              this.calculations[calculationTier] = calculation;
              if(calculationTier === this.draft.extra_search_params?.tariff_tier?.identifier) {
                this.calculation = calculation;
                this.initCalculation();
                this.applyCartVisibility();
              }
            }

            let tierDescriptor = this.tierDescriptorsMap.get(calculationTier);
            let cost = calculation?.calculation?.total_cost || 0;
            if(tierDescriptor) {
              if(calculationMinutes == null) {
                tierDescriptor.calculation = calculation;
                tierDescriptor.cost = cost;
                tierDescriptor.orderDuration = calculation.calculation.minutes;
              } else {
                let minutesCostDescriptor = tierDescriptor as any;
                for(const [descriptorField, minutes] of CALCULATION_MINUTES_VARIANTS) {
                  if(minutes === calculationMinutes)
                    minutesCostDescriptor[descriptorField] = cost;
                }
              }
            }
          }
        },
        e => {
          this.initCalculationStream();
          throw e;
        }
      );
  }

  private calculateDefaultDraft(): void {
    console.log('Calculating of default draft...');

    let defaultPoints = [[55.740434, 37.597785], [55.740434, 37.597785]];

    let draft = new OrderDraft();
    draft.is_default = true;
    draft.client = this.userInfoService.isPresent() ? this.userInfoService.userInfo!.account! : new Account();
    draft.extra_search_params = new ExtraSearchParams();
    draft.extra_search_params.tariff_tier = this.draft.extra_search_params?.tariff_tier;
    draft.loaders = this.draft.loaders;
    draft.pay_method = this.draft.pay_method;
    draft.auto_accept_taxi = true;
    draft.cargo_info = new CargoInfo();

    draft.destinations = [];
    for(let i = 0; i < 2; i++) {
      let destination = new DestinationPoint();
      let [ lat, lon ] = defaultPoints[i];
      destination.lat = lat;
      destination.lon = lon;

      let draftDestination = new Destination();
      draftDestination.destination = destination;

      draft.destinations[i] = draftDestination;
    }

    if(this.tiers.length > 0)
      this.calculationStream.next([ draft, this.draftSpecial ]);
  }

  private syncScrollWithSelectedTier(): void {
    setTimeout(() => {
      let tierEl = this.tiersContainer.nativeElement.querySelector(`.tier_${this.tierIdentifier}`);
      if(!tierEl)
        return;

      this.tiersContainer.nativeElement.scrollLeft
        = this.tiersContainer.nativeElement.scrollLeft + tierEl.getBoundingClientRect().left - 45;

    }, 250);
  }

  private initOrderDate(): void {
    let date = moment();
    let minutes = date.minutes();
    let minutesModulo = minutes % TIME_STEP;
    minutes = ORDER_START_TIME_OFFSET - minutesModulo;
    if(minutesModulo > TIME_STEP / 2)
      minutes += TIME_STEP;

    date.add(minutes, 'minutes');

    this.orderDate = date.format('YYYY-MM-DD');
    this.orderTime = date.format('HH:mm');
  }

  private initAdditionalAddressFields(): void {
    if(this.draft.destinations) {
      for(let i = 0; i < this.draft.destinations.length; i++)
        this.initAdditionalAddressFieldsOfDestination(i, this.draft.destinations[i]);
    }
  }

  private initAdditionalAddressFieldsOfDestination(num: number, destination: Destination): void {
    const checkingFields = [ 'floor', 'contact_name', 'contact_phone', 'lifting', 'elevator' ];
    let isDisplayRequired = false;
    for(const field of checkingFields) {
      const value = (destination as any)[field];
      if(value !== undefined && value !== null && value !== '') {
        isDisplayRequired = true;
        break;
      }
    }
    this.displayAdditionalAddressFields[num] = isDisplayRequired;
  }

  private saveDraft(): void {
    this.draftStorageService.saveDraft(this.draft);
  }

  private restoreDraft(): boolean {
    let draft = this.draftStorageService.restoreDraft();
    if(draft !== undefined) {
      this.draft = draft;
      this.tierIdentifier = this.draft.extra_search_params?.tariff_tier?.identifier;
      this.name = UserNameUtils.SNPToName(this.draft.client!);
      this.restoreOrderDate();
      this.restoreDestinationLiftTypes();

      return true;
    }
    return false;
  }

  private restoreDestinationLiftTypes(): void {
    if(!this.draft.destinations)
      return;

    for(let i = 0; i < this.draft.destinations.length; i++) {
      const destination = this.draft.destinations[i];
      const liftType = LiftTypeUtils.selectLiftTypeByDestination(destination);
      this.destinationLiftTypes[i] = liftType || LiftType.NoMove;
    }
  }

  private restoreOrderDate(): void {
    if(!this.draft.destinations || this.draft.destinations.length === 0)
      return;

    let destination = this.draft.destinations[0];
    if(!destination.arrival_time) {
      this.initOrderDate();
      return;
    }

    let orderDate = moment(destination.arrival_time, ARRIVAL_TIME_FORMAT);
    if(!orderDate.isValid()) {
      this.initOrderDate();
      return;
    }

    this.orderDate = orderDate.format('YYYY-MM-DD');
    this.orderTime = orderDate.format('HH:mm');
  }

  private removeDraftFromStorage(): void {
    this.draftStorageService.removeDraftFromStorage();
  }

  private initPhone(): void {
    this.phone = this.loginService.restorePhone() || "";
  }

  private recalculate(): void {
    if(this.tiers.length > 0)
      this.calculationStream.next([ this.draft, this.draftSpecial ]);
  }

  private isDraftCalculable(draft: OrderDraft): boolean {
    return this.isAllDraftDestinationsValid(draft);
  }

  private isAllDraftDestinationsValid(draft: OrderDraft): boolean {
    for(let destination of draft.destinations!) {
      if(!destination.destination?.lat || !destination.destination.lon)
        return false;
    }
    return true;
  }

  private initCalculationVariants(): void {
    let tierIdentifiers: string[] = [];
    let customMinutes: (number|null)[] = [];
    for(const tier of this.tiers) {
      tierIdentifiers.push(tier.identifier);
      customMinutes.push(null);

      for(const minutes of CALCULATION_MINUTES_VARIANTS) {
        tierIdentifiers.push(tier.identifier);
        customMinutes.push(minutes[1] as number);
      }
    }
    this.calculationVariants = {
      tier: tierIdentifiers,
      customMinutes: customMinutes
    };
  }

  private initDraft(): void {
    this.initDraftDestinations();
    this.draft.extra_search_params = new ExtraSearchParams();
    this.draft.loaders = 0;
    this.draft.pay_method = 'cash';
    this.draft.auto_accept_taxi = true;
    this.draft.cargo_info = new CargoInfo();

    this.draft.client = this.userInfoService.isPresent() ? this.userInfoService.userInfo!.account! : new Account();
    this.name = UserNameUtils.SNPToName(this.draft.client!);
  }

  private initDraftDestinations(): void {
    this.draft.destinations = [];

    for(let i = 0; i < 2; i++) {
      let destination = new DestinationPoint();

      let draftDestination = new Destination();
      draftDestination.destination = destination;

      this.draft.destinations[i] = draftDestination;
    }

    this.destinationLiftTypes[0] = LiftType.NoMove;
    this.destinationLiftTypes[1] = LiftType.NoMove;
  }

  private initAuthorizedUser(): void {
    this.loadEnteredPromocode();
    // this.loadBankCards();
  }

  private loadTiers(): void {
    if(this.reloadTiersTimer) {
      clearTimeout(this.reloadTiersTimer);
      this.reloadTiersTimer = undefined;
    }

    this.tariffService
      .getTaxiTiers(false, true)
      .subscribe(
        tiers => {
          this.tiers = tiers;
          if(tiers.length > 0 && !this.draft.extra_search_params!.tariff_tier) {
            this.tierIdentifier = tiers[0].identifier;
            this.draft.extra_search_params!.tariff_tier = tiers[0];
          }

          this.initCalculationVariants();
          this.remapTierDescriptions();
          this.applyTierIdentifierToInit();
          this.recalculate();
          this.syncScrollWithSelectedTier();
        },
        () => {
          this.reloadTiersTimer = setTimeout(() => this.loadTiers(), 10000);
        }
      );
  }

  private remapTierDescriptions(): void {
    this.tierDescriptors = [];
    this.tierDescriptorsMap.clear();
    for(let tier of this.tiers) {
      let descriptor = TIER_DESCRIPTORS[tier.identifier];
      if(descriptor) {
        descriptor = descriptor.cloneBase();
        this.tierDescriptors.push(descriptor);
        this.tierDescriptorsMap.set(descriptor.identifier, descriptor);
      }
    }
  }

  private loadBankCards(): void {
    this.bankCardService
      .getMyCards()
      .subscribe(cards => {
        this.bankCards = cards;
        this.activeCard = cards.find(c => c.active && !this.isCardExpired(c));
      })
    ;
  }

  private initDraftByQueryParams(params: Params): void {
    this.initDraftPointsByQueryParams(params);
    this.initDraftTierByQueryParams(params);
    this.initAddCardResult(params);
  }

  private initAddCardResult(params: Params): void {
    this.isAddCardFail = params['addCard'] && params['addCard'] == 'fail';
    this.addCardFailMessage = params['failMessage'] || '';
  }

  private initDraftPointsByQueryParams(params: Params): void {
    let i = 0;
    while(true) {
      let addr = params[`point[${i}][addr]`] || params[`point-${i}Addr`];
      let lat = params[`point[${i}][lat]`] || params[`point-${i}Lat`];
      let lon = params[`point[${i}][lon]`] || params[`point-${i}Lon`];

      if(!addr || !lat || !lon)
        break;

      let destination = new DestinationPoint();
      destination.addr = addr;
      destination.lat = parseFloat(lat);
      destination.lon = parseFloat(lon);

      let draftDestination = new Destination();
      draftDestination.destination = destination;

      this.draft.destinations![i] = draftDestination;

      i ++;
    }
  }

  private initDraftTierByQueryParams(params: Params): void {
    this.tierIdentifierToInit = params['tier'];
  }

  private applyTierIdentifierToInit(): void {
    if(!this.tierIdentifierToInit)
      return;

    for(let tier of this.tiers) {
      if(tier.identifier === this.tierIdentifierToInit) {
        this.draft.extra_search_params!.tariff_tier = tier;
        this.tierIdentifier = tier.identifier;
        return;
      }
    }
  }

  private requestCode(): void {
    this.isCodeRequesting = true;
    this.code = '';
    this.loginService
      .restorePassword(this.getFixedPhone())
      .finally(() => this.isCodeRequesting = false)
      .then(result => {
        switch(result) {
          case RestorePasswordResult.SystemError:
            this.alertService.error("Системная ошибка. Обратитесь к администратору.")
            break;
          case RestorePasswordResult.UnknownPhone:
            this.registerUser();
            break;
          case RestorePasswordResult.Ok:
            this.isCodeRequested = true;
            this.focusInToCodeField();
            break;
        }
      });
  }

  private focusInToCodeField(): void {
    this.acceptCodeField.focusIn();
  }

  private registerUser(): void {
    this.isCodeRequesting = true;
    this.registerAccountService
      .registerAccount(this.getFixedPhone())
      .pipe(
        finalize(() => this.isCodeRequesting = false),
      )
      .subscribe(
        result => {
          switch(result) {
            case RegisterResult.UserExists:
              this.alertService.warning('Пользователь с таким номером уже зарегистрирован');
              break;
            case RegisterResult.InvalidPhone:
              this.alertService.warning('Неверный формат номера телефона');
              break;
            case RegisterResult.SystemError:
              this.alertService.error('Ошибка на сервере');
              break;
            case RegisterResult.Ok:
              this.isCodeRequested = true;
              this.focusInToCodeField();
              break;
          }
        },
        e => {
          this.alertService.error(`Ошибка ${e.message}`);
          throw e;
        }
      )
  }

  private login(): void {
    if(this.isLoginProcessing)
      return;

    this.isLoginProcessing = true;
    const code = this.code;
    this.code = '';

    this.loginService
      .login(this.getFixedPhone(), code)
      .finally(() => this.isLoginProcessing = false)
      .then(result => {
        switch(result) {
          case LoginResult.AccessDenied:
            this.alertService.warning('Доступ запрещён');
            this.isLoginProcessing = false
            break;
          case LoginResult.NotFound:
            this.alertService.warning('Пользователь не найден');
            this.isLoginProcessing = false
            break;
          case LoginResult.IncorrectPassword:
            this.alertService.warning('Неверный код');
            this.isLoginProcessing = false
            break;
          case LoginResult.Ok:
            this.userInfoService.setup().finally(() => {
              this.isLoginProcessing = false;
              this.initAuthorizedUser();
              this.onCommonDraftChangeWithCalculation();
            });
            break;
        }
      })
      .catch(e => {
        this.alertService.error(`Ошибка: ${e.message}`);
        this.code = '';
        this.isLoginProcessing = false
        throw e;
      })
  }

  private logout(): void {
    this.logoutService
      .logout()
      .subscribe(
        () => {
          this.userInfoService.clear();
          this.code = '';
        }
      );
  }

  private addCard(): void {
    this.isAddCardActive = true;
    this.bankCardService
      .doAddCardRequest()
      .subscribe(
        requestId => {
          this.addCardRequestId = requestId;
          this.checkAddCardStatus();
        },
        e => {
          this.isAddCardActive = false;
          throw e;
        }
      );
  }

  private removeCard(card: BankCard): void {
    this.bankCardService
      .removeCard(card)
      .subscribe(
        () => this.loadBankCards()
      );
  }

  private checkAddCardStatus(): void {
    if(this.addCardRequestId === undefined)
      return;

    this.bankCardService
      .requestAddCardState(this.addCardRequestId)
      .subscribe(
        state => {
          switch(state.status) {
            case AddBankCardStatus.Declined:
              this.isAddCardActive = false;
              this.alertService.error('Запрос на добавление карты отклонён');
              break;
            case AddBankCardStatus.WaitOpenPage:
              this.bankCardService.openAddCardPage(state.access_token!);
              break;
            default:
              setTimeout(() => this.checkAddCardStatus(), 3000)
          }
        },
        e => {
          this.isAddCardActive = false;
          throw e;
        }
      );
  }

  private sendDraft(): void {
    this.isDraftSending = true;
    this.meService
      .updateMyName(this.draft.client!)
      .pipe(
        switchMap(() => this.draftService.addDraft(this.prepareDraftToSend(), this.draftSpecial)),
        switchMap(draftId => {
          this.draft.id = draftId;
          return this.draftService.startSearch(this.draft);
        })
      )
      .subscribe(
        () => {
          this.draftStorageService.resetNewDraftFlag();
          this.router.navigate([`/draft`, this.draft.id, 'success']);
        },
        e => {
          this.isDraftSending = false;
          throw e;
        }
      )
  }

  private prepareDraftToSend(): OrderDraft {
    let draftClone = JSON.parse(JSON.stringify(this.draft)) as OrderDraft;

    draftClone.comment = this.buildComment();
    draftClone.destinations![0].arrival_time = this.buildArrivalTime();

    return draftClone;
  }

  private buildArrivalTime(): string|undefined {
    let destination = this.draft.destinations![0];
    if(!destination.arrival_time)
      return undefined;

    let arrivalTime = moment(destination.arrival_time, ARRIVAL_TIME_FORMAT);
    return arrivalTime.isBefore(moment()) ? undefined : destination.arrival_time;
  }

  private buildComment(): string {
    let components: string[] = [];

    if(this.draft.cargo_info!.cargoTypes!.length > 0)
      components.push(`Груз: ${this.draft.cargo_info!.cargoTypes.map(t => t.toLowerCase()).join(', ')}`);

    if(this.draft.cargo_info?.weight)
      components.push(`Вес ${this.draft.cargo_info?.weight} кг`)

    let cargoComment = (this.draft.cargo_info?.comment || '').trim();
    if(cargoComment != '')
      components.push(`\n${cargoComment}\n`);

    let draftComment = (this.draft.comment || '').trim();
    if(draftComment != '')
      components.push(draftComment);

    return components.join('\n');
  }

  private countValidCards(): number {
    return this.bankCards.reduce((count, card) => count + (this.isCardExpired(card) ? 0 : 1), 0);
  }

  private convertDateTimeToDate(): Moment|undefined {
    const date = moment(this.orderDate, 'YYYY-MM-DD');
    const time = moment(this.orderTime, 'HH:mm');
    // const date = moment(this.orderDate, ORDER_DATE_FORMAT);
    // const time = moment(this.orderTime, ORDER_TIME_FORMAT);

    if(!date.isValid() || !time.isValid())
      return undefined;

    return date
      .hours(time.hours())
      .minutes(time.minutes())
      .seconds(time.seconds())
    ;
  }

  private applyOrderDateIf(): void {
    const destination = this.draft.destinations![0];

    const orderDate = this.convertDateTimeToDate();
    destination.arrival_time = orderDate === undefined ? undefined : orderDate.format(ARRIVAL_TIME_FORMAT);
    this.saveDraft();
  }

  private addDestination(): void {
    if(!this.draft.destinations)
      this.draft.destinations = [];

    let destination = new DestinationPoint();

    let draftDestination = new Destination();
    draftDestination.destination = destination;

    this.draft.destinations.push(draftDestination);

    this.destinationLiftTypes.push(LiftType.NoMove);

    this.initAdditionalAddressFieldsOfDestination(this.draft.destinations.length - 1, draftDestination);
  }

  private applyLiftTypeToDestination(destination: Destination, liftType: LiftType): void {
    LiftTypeUtils.applyLiftTypeToDestination(destination, liftType);
  }

  getMinutesWithUnits(minutes: number): string {
    return `${minutes} ${minutesPlural(minutes)}`;
  }

  getAdditionalTimeString(minutes: number): string {
    return `${minutes} доп. ${minutesPlural(minutes)}`;
  }

  getLoadersWorkHoursString(hours: number, loadersCount: number): string {
    return `${hours} ${hoursPlural(hours)} работы ${loadersPlural(loadersCount)}`;
  }

  isPhoneValid(): boolean {
    return this.phone != undefined && this.phonePattern.test(this.getFixedPhone());
  }

  isCodeValid(): boolean {
    return this.code.length == 4;
  }

  isCardExpired(card: BankCard): boolean {
    return BankCardUtils.isCardExpired(card);
  }

  isDraftValid(): boolean {
    this.errors = [];

    if(!this.isAllDraftDestinationsValid(this.draft)) {
      this.errors.push('Чтобы сделать заказ, укажите все адреса.');
    }

    if(!this.userInfoService.isPresent()) {
      this.errors.push('Чтобы сделать заказ, подтвердите свой номер телефона.');
    }

    if(this.draft.pay_method == 'card' && this.draft.pay_method_option !== 'link' && this.countValidCards() == 0) {
      this.errors.push('Чтобы сделать заказ, укажите банковскую карту.');
    }

    if(!this.draft.extra_search_params?.tariff_tier?.identifier) {
      this.errors.push('Чтобы сделать заказ, выберите тариф.');
      this.loadTiers();
    }

    return this.errors.length == 0;
  }

  private toggleCargoType(type: string): void {
    if(this.isCargoTypeSelected(type))
      this.unselectCargoType(type);
    else
      this.selectCargoType(type);
  }

  private selectCargoType(type: string): void {
    this.draft.cargo_info!.cargoTypes!.push(type);
  }

  private unselectCargoType(type: string): void {
    const selectedTypeIndex = this.draft.cargo_info!.cargoTypes.indexOf(type);
    if(selectedTypeIndex >= 0)
      this.draft.cargo_info!.cargoTypes.splice(selectedTypeIndex, 1);
  }

  private fixCalculationBlockPosition(pageInfo: any): void {
    let blockEl = this.calculationBlock?.nativeElement;
    if(!blockEl)
      return;

    let position = pageInfo.iframeWidth <= WIDTH_FOR_CART
      ? 0
      : Math.max(0, pageInfo.scrollTop - pageInfo.offsetTop - blockEl.parentElement.getBoundingClientRect().top);
    blockEl.style.position = 'relative';
    blockEl.style.top = `${position}px`;
    blockEl.style.transition = 'top 1s ease';
  }

  private fixCartBlockPosition(pageInfo: any): void {
    let blockEl = this.cartBlock?.nativeElement;
    if(!blockEl)
      return;

    let cartInitialOffset = pageInfo.iframeWidth <= WIDTH_FOR_SMALL_CART ? SMALL_CART_OFFSET : NORMAL_CART_OFFSET;
    let position = Math.max(0, pageInfo.scrollTop - pageInfo.offsetTop);
    let visibleFrameHeight = Math.min(pageInfo.windowHeight, pageInfo.windowHeight - pageInfo.offsetTop + pageInfo.scrollTop);
    let cartBlockRect = blockEl.getBoundingClientRect();
    blockEl.style.top = `${position + visibleFrameHeight - cartBlockRect.height - cartInitialOffset}px`;
    blockEl.style.bottom = 'auto';
    blockEl.style.transition = 'top 1s ease';

    this.switchCartVisibilityStream.next();
  }

  private deleteDestination(num: number): void {
    this.draft.destinations!.splice(num, 1);
  }

  private scrollToCalculation(): void {
    if(this.lastPageInfo)
      this.scrollParentToCalculation();
    else
      this.scrollSelfToCalculation();
  }

  private scrollSelfToCalculation(): void {
    if(!this.calculationBlock)
      return;

    const calculationPosition = this.calculationBlock.nativeElement.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: calculationPosition,
      left: window.scrollX,
      behavior: 'smooth'
    });
  }

  private getFixedPhone(): string {
    let matched = this.fixPhonePattern.exec(this.phone);
    return matched == null ? this.phone : ("7" + matched[1]);
  }

  private scrollParentToCalculation(): void {
    if(!this.calculationBlock)
      return;

    parentIFrame.scrollToOffset(0, this.calculationBlock.nativeElement.getBoundingClientRect().top);
  }

  private startAutoCancelConfirmation(): void {
    if(this.cancelConfirmationTimer) {
      clearTimeout(this.cancelConfirmationTimer);
      this.cancelConfirmationTimer = null;
    }

    this.cancelConfirmationTimer = setTimeout(() => this.deleteDestinationConfirmations = {}, 10 * 1000);
  }

  private enterPromocode(): void {
    this.promoService
      .enter(this.promocode.code)
      .subscribe(
        result => {
          if(result === EnterPromocodeResult.Success) {
            this.isPromocodeEnterSuccess = true;
            this.recalculate();
          } else {
            this.isPromocodeEnterSuccess = false;
            this.isVisiblePromocodeError = true;
            switch(result) {
              case EnterPromocodeResult.Conflict:
                this.promocodeError = 'Промокод уже введён';
                break;
              case EnterPromocodeResult.NotFound:
                this.promocodeError = 'Неизвестный промокод';
                break;
              case EnterPromocodeResult.UsedOther:
                this.promocodeError = 'Вы использовали другой промокод из этой акции';
                break;
            }
          }
        }
      )
    ;
  }

  private loadEnteredPromocode(): void {
    this.promoService
      .getEnteredPromocode()
      .subscribe(
        code => this.promocode = code || new PromoCode()
      )
    ;
  }

  private applyAssembly(): void {
    if(this.draft.assembly && !this.draft.loaders)
      this.draft.loaders = 1;

    this.onCommonDraftChangeWithCalculation();
  }

  private applyLoaders(): void {
    if(this.draft.loaders === 0 && this.draft.assembly)
      this.draft.assembly = false;

    this.onCommonDraftChangeWithCalculation();
  }

  private applyCartVisibility(): void {
    this.isCartVisible = this.calculation && !this.calculation.is_default && this.calculation.calculation.total_cost > 0;
  }

  isCargoTypeSelected(type: string): boolean {
    return this.draft.cargo_info!.cargoTypes.indexOf(type) >= 0;
  }

  private switchCartVisibility(): void {
    if(!this.cartBlock || !this.calculationBlock)
      return;

    const cartPosition = this.cartBlock.nativeElement.getBoundingClientRect().top;
    const calculationPosition = this.calculationBlock.nativeElement.getBoundingClientRect().top;

    if(cartPosition >= calculationPosition)
      this.isCartVisible = false;
    else
      this.applyCartVisibility();
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.onScroll();
  }

  onScroll(): void {
    this.switchCartVisibility();
  }

  onChangeTier(): void {
    for(let tier of this.tiers) {
      if(tier.identifier === this.tierIdentifier) {
        this.draft.extra_search_params!.tariff_tier = tier;
        break;
      }
    }
    this.onCommonDraftChangeWithCalculation();
    this.syncScrollWithSelectedTier();
  }

  onChangeName(): void {
    UserNameUtils.nameToSNP(this.name, this.draft.client!);
    this.saveDraft();
  }

  onChangePhone(): void {
    if(this.isPhoneValid()) {
      this.draft.client!.phone = this.getFixedPhone();
      this.loginService.savePhone(this.draft.client!.phone);
    } else {
      this.draft.client!.phone = undefined;
    }
    this.saveDraft();
  }

  onRequestCode(): void {
    this.requestCode();
  }

  onChangeCode(): void {
    if(this.isCodeValid())
      this.login();
  }

  onLogout(): void {
    this.saveDraft();
    this.logout();
  }

  onAddCard(): void {
    if(!this.userInfoService.isPresent()) {
      this.errors.push("Чтобы добавить карту, сначала подтвердите свой телефон");
      return;
    }

    if(!this.isAddCardActive)
      this.addCard();
  }

  onRemoveCard(card: BankCard): void {
    if(confirm('Удалить карту?'))
      this.removeCard(card);
  }

  onSendDraft(): void {
    if(this.isDraftValid() && !this.isDraftSending)
      this.sendDraft();

    if(ym)
      ym(85405267,'reachGoal','push_order');
  }

  onChangeOrderDate(): void {
    this.applyOrderDateIf();
  }

  onChangeOrderTime(): void {
    this.applyOrderDateIf();
  }

  onCommonDraftChange(): void {
    this.saveDraft();
    this.draftStorageService.setNewDraftFlag();
  }

  onCommonDraftChangeWithCalculation(): void {
    this.saveDraft();
    this.recalculate();
  }

  onChangeLiftType(destinationNum: number): void {
    let destination = this.draft.destinations![destinationNum];

    this.applyLiftTypeToDestination(destination, this.destinationLiftTypes[destinationNum]);

    if(destination.lifting && !this.draft.loaders)
      this.draft.loaders = 1;

    this.onCommonDraftChangeWithCalculation();
  }

  onChangeFloor(destinationNum: number): void {
    let destination = this.draft.destinations![destinationNum];
    if(destination.floor)
      return;

    this.destinationLiftTypes[destinationNum] = destinationNum == 0 ? LiftType.DownWithLift : LiftType.UpWithLift;
    this.onChangeLiftType(destinationNum);
  }

  onAddDestination(): void {
    this.addDestination();
  }

  onToggleCargoType(type: string): void {
    this.toggleCargoType(type);
    this.onCommonDraftChange();
  }

  onChangePayMethod(payMethod: string): void {
    this.draft.pay_method = payMethod;
    this.draft.pay_method_option = payMethod === 'card' ? 'link' : undefined;

    this.onCommonDraftChangeWithCalculation();
  }

  onFocusCodeField(event: MaskFocusEventArgs): void {
    event.selectionStart = event.selectionEnd = 0;
  }

  onReceivedPageInfo(info: any): void {
    this.lastPageInfo = info;
    this.fixPositionStream.next(info);
    // console.log(info);
  }

  onDeleteDestination(num: number): void {
    if(num > 1) {
      if(this.deleteDestinationConfirmations[num]) {
        this.deleteDestination(num);
        this.onCommonDraftChangeWithCalculation();
        this.deleteDestinationConfirmations = {};
      } else {
        this.deleteDestinationConfirmations[num] = true;
        this.startAutoCancelConfirmation();
      }
    }
  }

  onScrollToCalculation(): void {
    this.scrollToCalculation();
  }

  onOpenChat(): void {
    if(this.iFrameResizerService.isIframed)
      this.zone.run(() => this.router.navigate(['/support']));
    else
      this.jivoSiteService.show();
  }

  onEnterPromocode(): void {
    this.isPromocodeEnterSuccess = false;

    if(!this.userInfoService.isPresent()) {
      this.promocodeError = 'Сначала авторизуйтесь';
      this.isVisiblePromocodeError = true;
      return;
    }

    if(this.promocode.code.trim() == '') {
      this.promocodeError = 'Введите промокод';
      this.isVisiblePromocodeError = true;
      return;
    }

    this.isVisiblePromocodeError = false;
    this.enterPromocode();
  }

  onChangeAssembly(): void {
    this.applyAssembly();
  }

  onChangeLoaders(): void {
    this.applyLoaders();
  }
}
